import { render, staticRenderFns } from "./LinkedWorkouts.vue?vue&type=template&id=10560263&"
import script from "./LinkedWorkouts.vue?vue&type=script&lang=js&"
export * from "./LinkedWorkouts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VList,VSimpleTable,VSubheader})
